import React from "react"
import Layout from "../components/utils/Layout/Layout"
import { Seo } from "../components/seo"

const Prices = () => {
  return <Layout></Layout>
}

export default Prices

export const Head = () => (
  <Seo title="Binary Bioskop | Cenovnik" pathname="prices" />
)
